<template>
  <div>
    <Necker></Necker>
    <div class="container py-16 leading-loose">
      <h1 class="text-4xl text-primary font-bold mb-6">消費者權益須知</h1>
      <p>
        本消費者須知（以下簡稱本須知）訂立之目的，係為保障學習研究社網站jody.com.tw（以下簡稱本網站），以及所有在本網站進行線上交易之消費者權益。本網站註冊位置所在地為台灣，目前僅接受新台幣交易；消費者完成本網站之註冊手續、或開始利用本網站時，即視為已詳細閱讀本須知並同意如下約定：
      </p>

      <p class="text-primary text-2xl mt-8 mb-2">一、基本條款</p>
      <ul>
        <li>
          1、消費者完成本網站之註冊手續、或開始利用本網站時，即表示已完全閱讀、瞭解並同意接受本須知之全部內容，包括但不限於現有與未來衍生的項目及內容。
        </li>
        <li>
          2、消費者於任何修訂後繼續利用本網站時，亦視為已閱讀、瞭解並同意接受該等修改。若不同意上述修訂，或不接受本須知任一內容時，消費者應立即停止利用本網站。
        </li>
        <li>
          3、未滿二十歲之未成年人利用本網站時，應得法定代理人同意，並遵守本合約及相關法律規定。若未滿二十歲的未成年人利用或繼續利用本網站時，視為法定代理人（或監護人）已閱讀、瞭解並同意接受本須知之所有內容及其後續之修改。
        </li>
      </ul>

      <p class="text-primary text-2xl mt-8 mb-2">二、個人資料安全</p>
      <ul class="">
        <li>
          1、為了完成交易，包括付款及交貨等，所有在本網站進行線上交易的消費者，都必須擔保所留存的資料與事實相符，如事後有變更，應即時通知本網站站務管理者。若有資料不實或資料錯誤等問題，由消費者自行負責。
        </li>
        <li>
          2、對於消費者所留存的資料，本網站除了採用安全交易模式外，並承諾負保密義務，除了為完成該筆交易而提供給相關商品或服務之提供廠商或配合廠商以外，不會任意洩漏或提供給第三人。
        </li>
        <li>
          3、在下列情況下，本網站有權查看或提供消費者之個人資料予權責機關、或主張其權利受侵害並提出適當證明之第三人：
          <ul class="">
            <li>（1）依法令規定、或依司法機關或其他有權責機關之命令。</li>
            <li>（2）為執行本須知內容、或消費者違反約定內容時。</li>
            <li>（3）為維護本網站系統之正常運作及安全時。</li>
            <li>（4）為保護本網站、其他消費者、或其他第三人的合法權益時。</li>
          </ul>
        </li>
        <li>
          4、消費者對於個人資料、付款資料（包含信用卡資料）及會員密碼等，應妥善保管，避免外洩。所有使用其帳號和密碼進入本系統後之行為，均視為該帳號及密碼持有人之行為。消費者對自身的帳號和密碼因管理不善或不當的疏失，致所發生之一切損失應自行負責，本網站概不負責。
        </li>
      </ul>

      <p class="text-primary text-2xl mt-8 mb-2">三、商品服務價格及價格變化</p>
      <ul class="">
        <li>
          1、產品之價格將在本網站上標示。該類資訊可能隨時更改且不發出任何通知。
        </li>
        <li>
          2、若本網站商品發生錯誤定價時，將採取下列措施：
          <ul>
            <li>
              （1）若商品服務的正確定價低於本網站的錯誤定價時，本網站有權更正並按照實際標準定價向消費者銷售交付商品服務。
            </li>

            <li>
              （2）若商品服務的正確定價高於本網站的錯誤定價，本網站會根據情況決定，在交付商品前與消費者確認是否依正確定價購買商品服務，或者取消訂單。
            </li>
          </ul>
        </li>
      </ul>

      <p class="text-primary text-2xl mt-8 mb-2">四、線上兌換</p>
      <ul class="">
        <li>1、本須知所稱「兌換」，係指消費者於本網站線上換購商品服務。</li>
        <li>
          2、以兌換方式換得之商品服務，一經消費者兌換後，除因商品服務本身具有瑕疵外，均不得退還或更換。本項退還或更換，應於商品服務前七天內通知本網站。
        </li>
        <li>
          3、前項申請兌換之商品服務停止提供時，本網站有權變更商品服務項目，另代以其他等值之類似服務。
        </li>
      </ul>

      <p class="text-primary text-2xl mt-8 mb-2">五、訂單</p>
      <ul class="">
        <li>
          1、消費者一旦於本網站進行線上消費，即表示願意購買該商品或服務並願遵守交易規則。消費者資料（如地址、電話）如有變更時，將以訂單資料為最終資訊，如有變更時，不得以資料不符為理由，否認其訂購行為或拒絕付款。
        </li>
        <li>2、商品服務資訊均於本網網站上清楚標示，訂購前請先詳閱。</li>
        <li>
          3、所有在本網站進行的線上消費，消費者應同意以本網站所紀錄之電子交易資料為準，如有糾紛，並以該電子交易資料為認定標準。消費者如果發現交易資料不正確，應立即通知本網站站務管理者。
        </li>
      </ul>

      <p class="text-primary text-2xl mt-8 mb-2">六、產品相關售後服務</p>
      <p>
        客戶服務時間：星期一至星期五：9:00~12:00 / 13:00~18:00 <br />
        客服信箱：<a
          href="mailto:jody.cs.service@gmail.com"
          class="hover:text-primary dur150"
          >jody.cs.service@gmail.com</a
        >
        ; 聯絡電話：<a
          href="tel:02 - 8925-1958"
          class="hover:text-primary dur150"
          >02 - 8925-1958 </a
        >; 傳真專線：<a
          href="tel:02-8925-3598"
          class="hover:text-primary dur150"
          >02 - 8925-3598</a
        >
      </p>

      <p class="text-primary text-2xl mt-8 mb-2">七、服務終止</p>
      <p></p>
      <ul class="">
        <li>
          1、本網站為維持良好服務，盡可能維護保持系統良好之運行，在設備發生障礙或故障時，本網站會儘快修理恢復其運作。
        </li>
        <li>
          2、如有下列情形，本網站有權停止服務：
          <ul>
            <li>（1）進行補修工作及系統正常維護時。</li>
            <li>
              （2）天災、事變等不可抗力事件或通訊業者必須停止電子通訊服務的狀況時。
            </li>
          </ul>
        </li>
      </ul>

      <p class="text-primary text-2xl mt-8 mb-2">八、系統安全</p>
      <p>
        本網站系統不擔保消費者所上載的資料將被正常顯示、亦不擔保資料傳輸的正確性；如果消費者發現本系統有錯誤或疏失，請立即通知本網站站務管理者。
      </p>

      <p class="text-primary text-2xl mt-8 mb-2">
        九、本網站系統會定期備份資料，除非是本網站有故意或重大過失時，消費者應同意本網站不用對失誤刪除之資料或備份儲存失敗之資料負責。
      </p>

      <p class="text-primary text-2xl mt-8 mb-2">十、免責聲明</p>
      <p></p>
      <ul class="">
        <li>
          1、是否經由本網站之使用下載或取得任何資料應由消費者自行考量且自負風險，因前開任何資料之下載而導致電腦系統之任何損壞或資料流失，消費者應負完全責任。
        </li>
        <li>
          2、「本網站」就各項服務，不負任何明示或默示之擔保責任。「本網站」不保證各項服務之穩定、安全、無誤、及不中斷，消費者應明瞭並承擔使用本網站之所有風險及可能發生之任何損害。
        </li>
        <li>
          3、任何因駭客攻擊、電腦病毒侵入或發作或因政府管制而造成暫時性關閉等影響網路正常經營之不可抗力因素，致資料洩露、毀損、丟失、被盜用或被竄改等時，槪與本網站無關。
        </li>
        <li>
          4、本網站上刊登之個人廣告、商業廣告及各種商品之促銷資訊，除非屬於本網站所自行為之，否則衍生之交易糾紛，本網站概不負責。
        </li>
      </ul>

      <p class="text-primary text-2xl mt-8 mb-2">
        十一、本網站保留隨時修改本須知之權利，修改後的內容將公佈在本網站上，不另外個別通知消費者。
      </p>

      <p class="text-primary text-2xl mt-8 mb-2">十二、準據法及管轄權</p>
      <p>
        本須知之解釋與適用，以及與本須知有關之爭議，依照中華民國法律規定，雙方並同意以臺灣台北地方法院為第一審管轄法院。
      </p>
    </div>
  </div>
</template>

<script setup>
import Necker from '@/views/_necker/Index.vue';
import { provide, ref } from 'vue';

provide('page', {
  breadcrumbs: ref(['消費者權益須知']),
  title: ref('消費者權益須知'),
  pcBg: ref('banner04.png'),
  mobileBg: ref('banner04_m.png'),
});
</script>

<style lang="sass" scoped></style>
